<template>
  <div class="app-layout">
    <sunrise-main />
  </div>
</template>

<script>
  import {
    SunriseMain
  } from "./components";

  export default {
    name: "SunriseLayout",
    components: {
      SunriseMain
    }
  };
</script>

<style lang="scss" scoped>
  .app-layout {
    position: relative;
    min-height: 100%;
    width: 686px;
    max-width: 100%;
    margin: 0 auto;
  }
</style>
